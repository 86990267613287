
import getJSONLD from '~/utils/structured-data';
import { commonProps } from '../../utils/prismic';

export default {
  props: commonProps,
  head() {
    return {
      script: [getJSONLD('faq', this.slice.data)],
    };
  },
};
